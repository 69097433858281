
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserIcon',
  props: {
    color: String,
  },
  computed: {
    containerStyle(): string {
      return `${this.$props.color} w-9 h-9 rounded-full text-white flex flex-col justify-center
              items-center`;
    },
  },
});
