
import { defineComponent } from 'vue';
import PageTitle from '@/components/PageTitle.vue';
import EmployeeSidebar from '@/components/EmployeeSidebar.vue';
import ProgressSpinner from '@/components/ProgressSpinner.vue';

export default defineComponent({
  name: 'EmployeeDiagnosis',
  components: {
    ProgressSpinner,
    EmployeeSidebar,
    PageTitle,
  },
  data() {
    return {
      ssn: '',
      isShowingDiagnosis: false,
      isLoading: true,
      diagnosisToShow: [
        {
          name: 'Lesblinda',
          person: 'Rúnar Tryggvason',
        },
        {
          name: 'ADHD',
          person: 'Hjördís Ólafsdóttir',
        },
      ],
    };
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.isShowingDiagnosis = true;
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((r) => setTimeout(r, 500));
      this.isLoading = false;
    },
  },
});
