import { createStore } from 'vuex';
import State, { User } from '@/models/root_state';

const store = createStore<State>({
  state: {
    currentUser: {
      username: 'Jón Ólafur',
      otherUsers: ['Ármann Gísli', 'Rúna Dís'],
    },
    selectedUser: 0,
    diagnosis: [
      {
        name: 'Lesblinda',
        person: 'Rúnar Tryggvason',
      },
      {
        name: 'ADHD',
        person: 'Hjördís Ólafsdóttir',
      },
    ],
  },
  getters: {
    username(state) {
      if (state.currentUser === null) return 'None';

      if (state.selectedUser > 0) {
        return state.currentUser.otherUsers[state.selectedUser - 1];
      }

      return state.currentUser.username;
    },
  },
  mutations: {
    setUser(state, user: User) {
      state.currentUser = user;
    },
    selectUser(state, index: number) {
      state.selectedUser = index;
    },
  },
});

export default store;
