
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IndividualSidebar',
  data() {
    return {
      items: [
        { title: 'Greiningar', path: '/einstaklings/greiningar' },
        { title: 'Panta sálfræðiþjónustu', path: '/einstaklings/tjonusta' },
        { title: 'Skilaboð', path: '/einstaklings/skilabod' },
      ],
    };
  },
});
