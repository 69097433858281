
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TopBar',
  data() {
    return {
      isSiteSpaceMenuOpen: false,
      isUserMenuOpen: false,
      siteSpaces: [
        {
          title: 'Einstaklingsvefur',
          path: '/einstaklings',
        },
        {
          title: 'Starfsmannavefur',
          path: '/starfsmanna',
        },
      ],
    };
  },
  computed: {
    siteSpaceButtonTitle() {
      const { path } = this.$route;

      return path.startsWith('/starfsmanna') ? 'Starfsmannavefur' : 'Einstaklingsvefur';
    },
  },
  methods: {
    toggleSiteSpaceMenu(event: MouseEvent) {
      this.isSiteSpaceMenuOpen = !this.isSiteSpaceMenuOpen;
      event.stopPropagation();
    },
    navigateToSiteSpace(path: string) {
      this.$router.push(path);
      this.isSiteSpaceMenuOpen = false;
      this.$store.commit('selectUser', 0);
    },
    onHeaderClick() {
      this.isSiteSpaceMenuOpen = false;
      this.isUserMenuOpen = false;
    },
    toggleUserMenu(event: MouseEvent) {
      this.isUserMenuOpen = !this.isUserMenuOpen;
      event.stopPropagation();
    },
    logout() {
      this.$store.commit('selectUser', 0);
      this.$router.push('/');
    },
    selectUser(index: number) {
      this.$store.commit('selectUser', index + 1);
      this.$router.push('/einstaklings');
    },
  },
});
