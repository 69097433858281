
import { defineComponent } from 'vue';
import IndividualSidebar from '@/components/IndividualSidebar.vue';
import PageTitle from '@/components/PageTitle.vue';

export default defineComponent({
  name: 'Diagnosis',
  components: {
    PageTitle,
    IndividualSidebar,
  },
  computed: {
    diagnosies() {
      return this.$store.state.diagnosis;
    },
  },
  methods: {
    manageAccess(index: number) {
      this.$router.push(`/einstaklings/greiningar/${index}/adgangur`);
    },
  },
});
