import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-52 bg-slate-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({ title, path }) => {
        return (_openBlock(), _createElementBlock("li", { key: title }, [
          (_ctx.$route.path === path)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: path,
                class: "block px-1.5 py-2 bg-orange-600 bg-opacity-40 font-semibold"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(title), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: path,
                class: "block px-1.5 py-2 hover:bg-orange-600 hover:bg-opacity-20 transition-colors"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(title), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
        ]))
      }), 128))
    ])
  ]))
}