import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Diagnosis from '@/views/individual/Diagnosis.vue';
import Messages from '@/views/individual/Messages.vue';
import Booking from '@/views/individual/Booking.vue';
import Access from '@/views/individual/Access.vue';
import EmployeeDiagnosis from '@/views/Employee/EmployeeDiagnosis.vue';
import EmployeeMessages from '@/views/Employee/EmployeeMessages.vue';
import Login from '@/views/Login.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/einstaklings',
    redirect: (to) => ({ path: '/einstaklings/greiningar' }),
  },
  {
    path: '/starfsmanna',
    redirect: (to) => ({ path: '/starfsmanna/greiningar' }),
  },
  {
    path: '/einstaklings/greiningar',
    name: 'Diagnosis',
    component: Diagnosis,
  },
  {
    path: '/einstaklings/greiningar/:index/adgangur',
    name: 'DiagnosisAccess',
    component: Access,
  },
  {
    path: '/einstaklings/adgangur',
    name: 'Access',
    component: Access,
  },
  {
    path: '/einstaklings/tjonusta',
    name: 'Booking',
    component: Booking,
  },
  {
    path: '/einstaklings/skilabod',
    name: 'Messages',
    component: Messages,
  },
  {
    path: '/starfsmanna/greiningar',
    name: 'EmployeeDiagnosis',
    component: EmployeeDiagnosis,
  },
  {
    path: '/starfsmenn/skilabod',
    name: 'EmployeeMessages',
    component: EmployeeMessages,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
