
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BookingDialog',
  props: {
    place: Object as PropType<{ name: string; location: string; }>,
  },
  data() {
    return {
      timesLeft: [
        '10:05',
        '11:05',
        '12:05',
      ],
      timesRight: [
        '13:30',
        '14:30',
        '15:30',
      ],
      selectedTime: -1,
    };
  },
});
