
import { defineComponent } from 'vue';
import IndividualSidebar from '@/components/IndividualSidebar.vue';
import PageTitle from '@/components/PageTitle.vue';
import Checkbox from '@/components/Checkbox.vue';

export default defineComponent({
  name: 'Access',
  components: {
    Checkbox,
    PageTitle,
    IndividualSidebar,
  },
  computed: {
    saveButtonStyle() {
      const baseStyle = 'px-2 py-1 text-white rounded';

      if (this.isEdited) {
        return `${baseStyle} bg-green-500 hover:bg-green-600 transition-colors`;
      }

      return `${baseStyle} bg-slate-300`;
    },
    diagnosisName() {
      const { index } = this.$route.params;

      return this.$store.state.diagnosis[index].name;
    },
  },
  data() {
    return {
      institutions: [
        {
          name: 'Háskólinn í reykjavík',
          description: 'Veita námsráðgjöfum aðgang að gögnum',
          allowed: false,
        },
        {
          name: 'Viðbragðsaðilar',
          description: 'Veita viðbragðsaðilum aðgang að gögnum',
          allowed: false,
        },
      ],
      isEdited: false,
    };
  },
  methods: {
    onAccessAllowedChange() {
      this.isEdited = true;
    },
    saveChanges() {
      this.isEdited = false;
      this.$router.back();
    },
  },
});
