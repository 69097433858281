
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EmployeeSidebar',
  data() {
    return {
      items: [
        { title: 'Greiningar', path: '/starfsmanna/greiningar' },
        { title: 'Skilaboð', path: '/starfsmenn/skilabod' },
      ],
    };
  },
});
