
import { defineComponent } from 'vue';
import PageTitle from '@/components/PageTitle.vue';
import UserIcon from '@/components/UserIcon.vue';
import EmployeeSidebar from '@/components/EmployeeSidebar.vue';

export default defineComponent({
  name: 'EmployeeMessages',
  components: { EmployeeSidebar, UserIcon, PageTitle },
  data() {
    return {
      newMessage: '',
      selectedContact: 0,
      contacts: [
        {
          name: 'Jón Ólafur',
          title: 'Nemandi',
          iconCharacter: 'J',
          iconColor: 'bg-rose-800',
        },
      ],
      messages: [
        [],
        [],
      ] as string[][],
    };
  },
  methods: {
    selectContact(index: number) {
      this.selectedContact = index;
      this.newMessage = '';
    },
    sendMessage() {
      this.messages[this.selectedContact].push(this.newMessage);
      this.newMessage = '';
    },
    onNewMessageKeypress(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.sendMessage();
      }
    },
  },
});
