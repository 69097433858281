import { createApp } from 'vue';
import Axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import './css/main.css';

document.title = 'Greiningarvera';

Axios.defaults.baseURL = 'http://localhost:5028';

createApp(App).use(store).use(router).mount('#app');
