
import { defineComponent } from 'vue';
import Axios from 'axios';

export default defineComponent({
  name: 'Login',
  data() {
    return {
      isLoggingIn: false,
      phone: '',
    };
  },
  methods: {
    async onLogin() {
      this.isLoggingIn = true;
      // eslint-disable-next-line no-promise-executor-return
      const res = await Axios.post('https://api.greiningarvera.gudmunduro.com/authenticate', {
        phone: this.phone,
      });
      this.isLoggingIn = false;

      this.$store.commit('setUser', {
        username: res.data.name as string,
        otherUsers: ['Ármann Gísli', 'Rúna Dís'],
      });
      this.$router.push('/einstaklings');
    },
    onPhoneKeypress(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.onLogin();
      }
    },
  },
});
