
import { defineComponent } from 'vue';
import IndividualSidebar from '@/components/IndividualSidebar.vue';
import PageTitle from '@/components/PageTitle.vue';
import BookingDialog from '@/components/BookingDialog.vue';

export default defineComponent({
  name: 'Booking',
  components: {
    BookingDialog,
    PageTitle,
    IndividualSidebar,
  },
  data() {
    return {
      openDialogIndex: -1,
      places: [
        {
          name: 'SÓL sálfræði- og læknisþjónusta',
          location: 'Hlíðasmára 14, kópavogi',
        },
        {
          name: 'Líf og sálfræði',
          location: 'Suðurlandsbraut 24, reykjavík',
        },
        {
          name: 'Sálfræðistofa Reykjavíkur',
          location: 'Borgartúni 31, reykjavík',
        },
      ],
    };
  },
});
