
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Checkbox',
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      checked: this.$props.modelValue,
    };
  },
  watch: {
    modelValue(newValue: boolean, oldValue: boolean) {
      this.checked = newValue;
    },
    checked(newValue: boolean, oldValue: boolean) {
      this.$emit('update:modelValue', newValue);
      this.$emit('changed', newValue);
    },
  },
  methods: {
    onClick() {
      this.checked = !this.checked;
    },
  },
});
